<template>
  <div class="faq__container">

<!--
    <img src="@/assets/img/faq__rictangle.png" alt="" class="faq__imq__tringl">
-->
    <img src="https://dbwImages.b-cdn.net/faq__rictangle.png" alt="" class="faq__imq__tringl">


    <img src="@/assets/img/tringl__mobile.png" alt="" class="faq__imq__tringl__mobile">
    <img src="https://dbwImages.b-cdn.net/bg__two__portfolio.svg" alt="" class="background__mobile__two">
<!--
    <img src=  "~@/assets/img/bg__two__portfolio.svg" alt="" class="background__mobile__two">
-->




    <div class="faq__title">
  <p class="faq__title__text">TRUSTED<span class="faq__title__text__another__color"> BY</span></p>
    <div class="faq__span__container">
      <span class="faq__span__one"></span>
      <span class="faq__span__two"></span>
    </div>
  </div>


<div class="faq__brands__grid__container">
    <div
        class="brands__grid__container"
        v-for="item in trustedHomeBrands" :key="item.id"

    >
        <img  class="item__brands__img" :src="item.image" :alt="item.id" loading="lazy">
    </div>
    </div>


    <div   :class="[this.marginFaq ? 'margin__faq' : 'faq__title__faq']">
      <p class="faq__title__text faq__title__text__position">FAQ</p>
      <div class="faq__span__container__faq">
        <span class="faq__span__one__faq"></span>
        <span class="faq__span__two__faq"></span>
      </div>
      <p class="faq__title__text__small__text">
        For Video Retouching Services
        from Digital Beauty Work
      </p>
  </div>

<div class="details__block">

  <accordion v-if="staticAccordion">
    <accordion-item class="accordion__item" id="item1">
      <template slot="accordion-trigger">
        <h3 class="accordion__title">
          What is Video Retouching and Why Would I Need it?
        </h3>
      </template>
      <template slot="accordion-content">
        <div class="details__span__container">
          <span class="details__span__one"></span>
          <span class="details__span__two"></span>
        </div>
        <span >
         Video retouching is the artform of adjusting and correcting all or parts of a recorded
          video.
          <br>
          <br>
          For example, lighting can be adjusted, blemishes corrected, and objects can be blurred. It is even possible to
          remove an item from your video's background completely.
          <br>
          <br>
          We can enhance the beauty and make imperfections perfect through our digital
          magic processes.
          <br>
          <br>
          These adjustments and corrections enhance your video content to create polished, flawless results.
        </span>
      </template>
    </accordion-item>

    <accordion-item class="accordion__item" id="item2">
      <template slot="accordion-trigger">
        <h3 class="accordion__title">
          How to Retouch a Video and Create a Flawless, Beautiful Clip?
        </h3>
      </template>
      <template slot="accordion-content">
        <div class="details__span__container">
          <span class="details__span__one"></span>
          <span class="details__span__two"></span>
        </div>
           <span >
             Video retouching is the artform of adjusting and correcting all or parts of a recorded
          video.
          <br>
          <br>
          For example, lighting can be adjusted, blemishes corrected, and objects can be blurred. It is even possible to
          remove an item from your video's background completely.
          <br>
          <br>
          We can enhance the beauty and make imperfections perfect through our digital
          magic processes.
          <br>
          <br>
          These adjustments and corrections enhance your video content to create polished, flawless results.
        </span>
      </template>
    </accordion-item>

    <accordion-item class="accordion__item" id="item3">
      <template slot="accordion-trigger">
        <h3 class="accordion__title">
          Why Video Retouching is Important in All Aspects of Video Content Creation?
        </h3>
      </template>
      <template slot="accordion-content">
        <div class="details__span__container">
          <span class="details__span__one"></span>
          <span class="details__span__two"></span>
        </div>
     <span >
          Video retouching is the artform of adjusting and correcting all or parts of a recorded
          video.
          <br>
          <br>
          For example, lighting can be adjusted, blemishes corrected, and objects can be blurred. It is even possible to
          remove an item from your video's background completely.
          <br>
          <br>
          We can enhance the beauty and make imperfections perfect through our digital
          magic processes.
          <br>
          <br>
          These adjustments and corrections enhance your video content to create polished, flawless results.
        </span>
      </template>
    </accordion-item>
      <accordion-item class="accordion__item" id="item1" v-show="opened">
          <template slot="accordion-trigger">
              <h3 class="accordion__title">
                  What is Video Retouching and Why Would I Need it?
              </h3>
          </template>
          <template slot="accordion-content">
              <div class="details__span__container">
                  <span class="details__span__one"></span>
                  <span class="details__span__two"></span>
              </div>
              <span >
         Video retouching is the artform of adjusting and correcting all or parts of a recorded
          video.
          <br>
          <br>
          For example, lighting can be adjusted, blemishes corrected, and objects can be blurred. It is even possible to
          remove an item from your video's background completely.
          <br>
          <br>
          We can enhance the beauty and make imperfections perfect through our digital
          magic processes.
          <br>
          <br>
          These adjustments and corrections enhance your video content to create polished, flawless results.
        </span>
          </template>
      </accordion-item>
      <accordion-item class="accordion__item" id="item1" v-show="opened">
          <template slot="accordion-trigger">
              <h3 class="accordion__title">
                  What is Video Retouching and Why Would I Need it?
              </h3>
          </template>
          <template slot="accordion-content">
              <div class="details__span__container">
                  <span class="details__span__one"></span>
                  <span class="details__span__two"></span>
              </div>
              <span >
         Video retouching is the artform of adjusting and correcting all or parts of a recorded
          video.
          <br>
          <br>
          For example, lighting can be adjusted, blemishes corrected, and objects can be blurred. It is even possible to
          remove an item from your video's background completely.
          <br>
          <br>
          We can enhance the beauty and make imperfections perfect through our digital
          magic processes.
          <br>
          <br>
          These adjustments and corrections enhance your video content to create polished, flawless results.
        </span>
          </template>
      </accordion-item>
      <accordion-item class="accordion__item" id="item1" v-show="opened">
          <template slot="accordion-trigger">
              <h3 class="accordion__title">
                  What is Video Retouching and Why Would I Need it?
              </h3>
          </template>
          <template slot="accordion-content">
              <div class="details__span__container">
                  <span class="details__span__one"></span>
                  <span class="details__span__two"></span>
              </div>
              <span >
         Video retouching is the artform of adjusting and correcting all or parts of a recorded
          video.
          <br>
          <br>
          For example, lighting can be adjusted, blemishes corrected, and objects can be blurred. It is even possible to
          remove an item from your video's background completely.
          <br>
          <br>
          We can enhance the beauty and make imperfections perfect through our digital
          magic processes.
          <br>
          <br>
          These adjustments and corrections enhance your video content to create polished, flawless results.
        </span>
          </template>
      </accordion-item>


  </accordion>
  <div class="show__more__button" @click="showItems()" v-if="needShowButton">
    {{changeTextButton}}
    <img src="~@/assets/img/arrow__faq.svg" alt="" class="accordion__trigger__img__button" :class="{'accordion__trigger__img__button__active': opened}">
  </div>


</div>

  </div>


</template>

<script>
/*
import Accordion from "./accordion";
*/
const Accordion = () => import('./accordion');
const AccordionItem = () => import('./accordion-item');

/*
import AccordionItem from "./accordion-item";
*/
import {mapGetters} from "vuex";
export default {
  name: "FaqBlock",
  components: {
    Accordion,
    AccordionItem
  },
  computed: {
    ...mapGetters([
      'trustedHomeBrands',
    ])
  },
    props: {
        staticAccordion: {
            type: Boolean,
            require: true
        },
        marginFaq: {
            type: Boolean,
            require: false
        },
        needShowButton: {
            type: Boolean,
            require: true,
        }
    },
  data() {
    return {
      showMoreAccordions: false,
      opened:false,
      changeTextButton: 'Show more',
    }
  },
  mounted() {
/*
    if (window.innerWidth >= 981) {
*/
      this.showMoreAccordions = true
    /*}*/
  },

  methods: {

    showItems() {
/*
      if (window.innerWidth <= 981) {
*/
        this.showMoreAccordions = !this.showMoreAccordions;
        this.opened = !this.opened;
        if(!this.opened){
          this.changeTextButton = 'Show more';
        }else {
          this.changeTextButton = 'Show less';
        }
      }
/*
    },
*/
  },
}
</script>

<style scoped lang="scss">


.faq__brands__grid__container{
  width: 46.458vw;
  height: 32.760vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 5.208vw;
  grid-row-gap: 2.5vw;
  margin-top: 19vw;
  margin-right: 8.958vw;
  align-items: center;
  justify-items: center
}
.brands__grid__container{
  width: 6.208vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item__brands__img{
  width: 100%;
}

.faq__container{
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  height: 100%;
  position: relative;
 /* background-image: url("~@/assets/img/faq__background.png");*/
  background-image: url("https://dbwImages.b-cdn.net/faq__background.png");
  background-size: contain;
}
.faq__imq__tringl{
  position: absolute;
  top: -11vw;
  left: 0;
  width: 39.948vw;
  height: 92.969vw;
}
.faq__imq__tringl__mobile {
  display: none;
}
.faq__title{
  position: absolute;
  width: 20.885vw;
  height: 4.323vw;
  z-index: 2;
  left: 8vw;
  top: 10vw;
}

.faq__title__text{
  font-family: 'Benzin-ExtraBold';
  color: #FFFFFF;
  font-size: 2.083vw;
  text-align: left;
}
.faq__title__text__another__color{
  color: rgba(219, 16, 35, 1);
}
.faq__span__container{
  margin-top: 2.083vw;
  display: flex;
  width: 20.626vw;
  height: 0.156vw;
}
.faq__span__one{
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(219, 16, 35, 1);
}
.faq__span__two{
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(255, 255, 255, 1);
}
/*.faq__brands__grid__container{
  width: 42.776vw;
  height: 24.583vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 3.844vw;
  grid-row-gap: 3.7vw;
  margin-top: 24vw;
  margin-right: 9.896vw;
  align-items: center;
  justify-items: center;
}*/
.faq__brands__grid__container__mobile{
  display: none;
}
/*.faq__brands__grid__container__item__img__adidas{
  width: 6.700vw;
  height: 4.375vw;
}
.faq__brands__grid__container__item__img__ikea{
  width: 8.646vw;
  height: 1.771vw;
}
.faq__brands__grid__container__item__img__reebok{
  width: 7.083vw;
  height: 2.448vw;
}
.faq__brands__grid__container__item__img__macdonalds{
  width: 4.830vw;
  height: 3.438vw;
}
.faq__brands__grid__container__item__img__sun{
  width: 5.972vw;
  height: 5.625vw;
}
.faq__brands__grid__container__item__img__zara{
  width: 5.625vw;
  height: 2.865vw;
}
.faq__brands__grid__container__item__img__nike{
  width: 7.323vw;
  height: 2.604vw;
}
.faq__brands__grid__container__item__img__pepsi{
  width: 4.479vw;
  height: 4.479vw;
}
.faq__brands__grid__container__item__img__puma{
  width: 5.921vw;
  height: 4.479vw;
}
.faq__brands__grid__container__item__img__dior{
  width: 7.813vw;
  height: 2.448vw;
}
.faq__brands__grid__container__item__img__cocacola{
  width: 9.115vw;
  height: 2.917vw;
}
.faq__brands__grid__container__item__img__vector{
  width: 6.336vw;
  height: 4.427vw;
}*/

.faq__title__faq{
  width: 20.885vw;
  height: 6.563vw;
  margin-top: 11.250vw;
  margin-right: 9.896vw;
  margin-bottom: 9.896vw;
}
.margin__faq{
  width: 20.885vw;
  height: 6.563vw;
  margin-top: 11.250vw;
  margin-right: 9.896vw;
  margin-bottom: 0;
}
.faq__title__text__position{
  text-align: right;
}
.faq__span__container__faq{
  margin-top: 2.083vw;
  display: flex;
  width: 20.885vw;
  height: 0.156vw;
}
.faq__span__one__faq{
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(255, 255, 255, 1);
}
.faq__span__two__faq{
  height: 0.156vw;
  width: 10.313vw;
  background: #db1023;
}
.faq__title__text__small__text{
  margin-top: 2.083vw;
  font-size: 0.938vw;
  font-family: 'Benzin-Regular';
  line-height: 118%;
  text-align: right;
}
.details__block{
  width: 50.625vw;
  height: max-content;
  margin-right: 10.156vw;
  margin-bottom: 10vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.accordion__item{
  margin-bottom: 2.083vw;
  border-top: 0.052vw solid #FFFFFF;
  border-left: 0.052vw solid #FFFFFF;
  border-bottom: 0.052vw solid #FFFFFF;
  border-right: 0.052vw solid #FFFFFF;
}
.accordion__title{
  font-family: 'Benzin-Regular';
  color: #F5E9E9;
  font-size: 1.354vw;
  padding-right: 3.750vw;
  line-height: 1.563vw;
  padding-left: 1.563vw;
 }




.details__span__container__text{
  font-size: 0.938vw;
  color: #F2EFEF;
  font-family: 'Benzin-Regular';
  text-align: left;
}
.details__span__container{
  margin-top: 1.302vw;
  margin-bottom: 1.302vw;
  display: flex;
  width: 20.885vw;
  height: 0.156vw;
}
.details__span__one{
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(0, 139, 72, 1);
}
.details__span__two{
  height: 0.156vw;
  width: 10.313vw;
  background: rgba(255, 255, 255, 1);
}
.show__more__button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.background__mobile__two{
  display: none;
}
.accordion__trigger__img__button{
  margin-left: 2vw;
}
.accordion__trigger__img__button__active{
  transform: rotate(180deg);
  transition: 0.5s;
}
@media screen and(max-width: 991px) {
  .faq__brands__grid__container{
    width: 64.776vw;
    height: 47.467vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 4vw;
    grid-row-gap: 6.000vw;
    margin-top: 45.333vw;
    margin-right: 24vw;
    align-items: center;
    justify-items: center;
  }
  .brands__grid__container{
    width: 7.200vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item__brands__img{
    width: 100%;
  }




  .accordion__trigger__img__button__active{
    transform: rotate(180deg);
    transition: 0.5s;
  }
  .background__mobile__two{
    display: block;
    position: absolute;
    z-index: 0;
    top: 151vw;
    right: 0;
    height: 32.667vw;
    width: 57.933vw;
    rotate: 180deg;
  }
  .faq__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .faq__imq__tringl{
    display: none;
  }
  .faq__imq__tringl__mobile{
    display: block;
    position: absolute;
    top: -19vw;
    right: 0;
    width: 42.400vw;
    height: 107.467vw;
  }


  .faq__title{
    width: 50.667vw;
    height: 12.267vw;
    left: 7vw;
    top: 13vw;
  }
  .faq__title__text{
    font-size: 5.333vw;
  }
  .faq__span__container{
    margin-top: 6.667vw;
    width: 50.667vw;
    height: 0.267vw;
  }
  .faq__span__one{
    height: 0.267vw;
    width: 25.019vw;
  }
  .faq__span__two{
    height: 0.267vw;
    width: 25.019vw;
  }


  .faq__title__faq{
    margin-top: 25.333vw;
    margin-left: 28.267vw;
    margin-bottom: 10.667vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 69.333vw;
    height: 30.133vw;
  }
  .margin__faq{
    margin-top: 25.333vw;
    margin-left: 28.267vw;
    margin-bottom: 10.667vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 69.333vw;
    height: 30.133vw;
  }

  .faq__span__container__faq{
    display: flex;
    justify-content: right;
    margin-top: 6.667vw;
    width: 50.667vw;
    height: 0.267vw;
  }
  .faq__span__one__faq{
    height: 0.267vw;
    width: 25.019vw;
  }
  .faq__span__two__faq{
    height: 0.267vw;
    width: 25.019vw;;
  }
  .faq__title__text__small__text{
    margin-top: 6.667vw;
    font-size: 3.733vw;
    line-height: 4.267vw;
    text-align: right;
  }



  /*.faq__brands__grid__container{
    display: none;
  }
  .faq__brands__grid__container__mobile{
    display: block;
    width: 64.776vw;
    height: 32.583vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 5.333vw;
    grid-row-gap: 8.000vw;
    margin-top: 45.333vw;
    margin-right: 21.333vw;
    align-items: center;
    justify-items: center;
  }
  .faq__brands__grid__container__item__img__adidas__mobile{
    width: 8.267vw;
    height: 5.600vw;
  }
  .faq__brands__grid__container__item__img__transport__mobile{
    width: 8.533vw;
    height: 4.000vw;
  }
  .faq__brands__grid__container__item__img__reebok__mobile{
    width: 9.333vw;
    height: 3.733vw;
  }
  .faq__brands__grid__container__item__img__macdonalds__mobile{
    width: 7.200vw;
    height: 5.333vw;
  }
  .faq__brands__grid__container__item__img__sun__mobile{
    width: 8.533vw;
    height: 6.400vw;
  }
  .faq__brands__grid__container__item__img__zara__mobile{
    width: 10.933vw;
    height: 3.467vw;
  }
  .faq__brands__grid__container__item__img__nike__mobile{
    width: 9.067vw;
    height: 3.467vw;
  }
  .faq__brands__grid__container__item__img__puma__mobile{
    width: 7.733vw;
    height: 5.867vw;
  }
  .faq__brands__grid__container__item__img__vector__mobile{
    width: 9.333vw;
    height: 4.800vw;
  }
  .faq__brands__grid__container__item__img__square__mobile{
    width: 9.600vw;
    height: 4.267vw;
  }
  .faq__brands__grid__container__item__img__cocacola__mobile{
    width: 13.333vw;
    height: 4.267vw;
  }
  .faq__brands__grid__container__item__img__tvs__mobile{
    width: 16.800vw;
    height: 3.467vw;
  }*/


  .details__block{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 87.467vw;
    height: max-content;

    margin: 0 auto;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .accordion__item{
    margin-bottom: 8.000vw;
    border-top: 0.267vw solid #FFFFFF;
    border-left: 0.267vw solid #FFFFFF;
    border-bottom: 0.267vw solid #FFFFFF;
    border-right: 0.267vw solid #FFFFFF;
  }
  .accordion__title{
    font-family: 'Benzin-Regular';
    color: #F5E9E9;
    font-size: 4.000vw;
    line-height: 4.800vw;
    padding-left: 5.333vw;
    padding-right: 6.133vw;
  }

  .details__span__container__text{
    font-size: 0.938vw;
    color: #F2EFEF;
    font-family: 'Benzin-Regular';
    text-align: left;
  }
  .details__span__container{
    margin-bottom: 5.333vw;
    display: flex;
    width: 40.000vw;
    height: 0.267vw;
  }
  .details__span__one{
    height: 0.267vw;
    width: 19.867vw;
  }
  .details__span__two{
    height: 0.267vw;
    width: 19.867vw;
  }

  .show__more__button{
    left: 1vw;
    position: relative;
    display: block;
    width:  42.667vw;
    height: 6.933vw;
    font-size: 4.800vw;
    font-family: 'Benzin-Regular';
    margin-top: 10.133vw;
    margin-bottom: 22.400vw;


  }
  .accordion__trigger__img__button{
    width: 5vw;
    height: 5vw;
    position: absolute;
    top: 2vw;
    right: 3vw;
  }

}


</style>